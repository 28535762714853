<template>
  <div>
    <!-- 卡片视图区域 -->
    <el-card>
      <!-- 第一行，新增 -->
      <el-row>
        <!-- 添加按钮 -->
        <el-col :span="5">
          <el-button
            type="primary"
            @click="showAddShopDialog"
          >+ 新增商户</el-button>
        </el-col>
      </el-row>
      <!-- 第二行，搜索和筛选 -->
      <div class="secondrow">
        <!-- 店铺搜索 -->
        <div class="input">
          <!-- 输入框，尾部带按钮 -->
          店铺名称：
          <el-input
            placeholder="请输入店铺名称"
            v-model="queryInfo.shopName"
            clearable
            @clear="searchShop"
            @change="searchShopList"
          >
            <el-button
              slot="append"
              @click="searchShopList"
            >搜索</el-button>
          </el-input>
        </div>
        <!-- 姓名搜索 -->
        <div class="input">
          <!-- 输入框，尾部带按钮 -->
          姓名：
          <el-input
            placeholder="请输入经营人"
            v-model="queryInfo.userName"
            clearable
            @clear="searchShop"
            @change="searchShopList"
          >
            <el-button
              slot="append"
              @click="searchShopList"
            >搜索</el-button>
          </el-input>
        </div>
        <!-- 类别筛选 -->
        <div class="input">
          <!-- 筛选框 -->
          经营范围：
          <el-select
            v-model="queryInfo.merchantCategoryId"
            clearable
            placeholder="请选择经营范围"
            @clear="searchShop"
            @change="searchShopList"
          >
            <el-option
              v-for="item in cateList"
              :key="item.id"
              :label="item.categoryName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <!-- 状态筛选 -->
        <div class="input">
          <!-- 筛选框 -->
          状态：
          <el-select
            v-model="queryInfo.status"
            placeholder="请选择状态"
            clearable
            @clear="searchShop"
            @change="searchShopList"
          >
            <el-option
              v-for="item in statusList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <!-- 内容主体 -->
      <el-table
        :data="shopList"
        stripe
        style="width: 100%"
        border
      >
        <!-- 第一列id -->
        <el-table-column
          label="商户id"
          width="100"
          aligin="center"
          prop="id"
        >
        </el-table-column>
        <!-- 第二列店铺名称 -->
        <el-table-column
          label="店铺名称"
          aligin="center"
          prop="merchantsInfo.shopName"
        >
        </el-table-column>
        <!-- 第三列用户头像 -->
        <el-table-column
          label="头像"
          aligin="center"
          prop="avatar"
        >
          <template slot-scope="scope">
            <el-popover
              trigger="hover"
              title=""
              placement="bottom"
              v-if="scope.row.avatar"
            >
              <img
                :src="scope.row.avatar"
                width="200"
                height="200"
              />
              <img
                slot="reference"
                :src="scope.row.avatar"
                style="height: 80px;width: 80px"
              />
            </el-popover>
          </template>
        </el-table-column>
        <!-- 第四列经营人 -->
        <el-table-column
          label="经营人"
          aligin="center"
          prop="userName"
        >
        </el-table-column>
        <el-table-column
          label="性别"
          aligin="center"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.sex === 1">男</div>
            <div v-else-if="scope.row.sex === 0">女</div>
            <div v-else>未设置</div>
          </template>
        </el-table-column>
        <!-- 第五列联系方式 -->
        <el-table-column
          label="联系方式"
          aligin="center"
          prop="mobile"
        >
        </el-table-column>
        <!-- 第六列经营范围 -->
        <el-table-column
          label="经营范围"
          aligin="center"
        >
          <template slot-scope="scope">
            <span
              v-for="(item, index) in scope.row.merchantsInfo
                .merchantCategoryList"
              :key="index"
            >
              {{ item.categoryName }}
            </span>
          </template>
        </el-table-column>
        <!-- 第七列店铺有效期 -->
        <el-table-column
          label="店铺有效期"
          width="180"
          aligin="center"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.merchantsInfo.businessBegin">
              {{ scope.row.merchantsInfo.businessBegin }}至
              {{ scope.row.merchantsInfo.businessEnd }}
            </div>
          </template>
        </el-table-column>
        <!-- 第八列状态 -->
        <el-table-column
          label="状态"
          aligin="center"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.status === 0">
              启用
            </div>
            <div v-if="scope.row.status === 1">禁用</div>
            <div v-if="scope.row.status === 3">待完善</div>
          </template>
        </el-table-column>
        <!-- 第十列操作 -->
        <el-table-column
          label="操作"
          aligin="center"
          width="150"
        >
          <!-- 修改按钮 -->
          <template slot-scope="scope">
            <!-- 状态 0-启用 1-禁用 2-待完善 -->
            <div v-if="scope.row.status === 0">
              <el-button
                size="mini"
                type="primary"
                @click="showEditDialog(scope.row.id)"
              >修改</el-button>
              <!-- 禁用 -->
              <el-button
                size="mini"
                type="danger"
                @click="forbidden(scope.row)"
              >禁用</el-button>
            </div>
            <div v-else-if="scope.row.status === 1">
              <!-- 启用 -->
              <el-button
                size="mini"
                type="success"
                @click="start(scope.row)"
              >启用</el-button>
              <!-- 删除按钮 -->
              <el-button
                size="mini"
                type="danger"
                @click="removeShopById(scope.row.id)"
              >删除</el-button>
            </div>
            <div v-else>
              <!-- 完善 -->
              <el-button
                size="mini"
                type="warning"
                @click="showEditDialog(scope.row.id)"
              >完善</el-button>
              <!-- 删除按钮 -->
              <el-button
                size="mini"
                type="danger"
                @click="removeShopById(scope.row.id)"
              >删除</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNo"
        :page-sizes="[15, 30, 50, 100]"
        :page-size="queryInfo.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 添加商户对话框 -->
    <el-dialog
      :title="title"
      :visible.sync="addShopDialogVisible"
      width="800px"
      @close="clearInfo"
    >
      <el-form
        ref="addShopFormRef"
        :rules="addShopFormRules"
        :model="merchantsForm"
        label-width="100px"
      >
        <div class="first">
          <el-form-item
            label="经营人："
            prop="userName"
          >
            <el-input
              v-model="merchantsForm.userName"
              placeholder="请输入经营人姓名"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item
            label="性别："
            prop="sex"
          >
            <el-select
              v-model="merchantsForm.sex"
              placeholder="请选择性别"
              clearable
              class="select"
            >
              <el-option
                v-for="(item, index) in sexList"
                :key="index"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="second">
          <el-form-item
            label="身份证号码："
            prop="id"
            ref="file_Rule"
          >
            <el-input
              v-model="merchantsForm.merchantsInfo.idCardNum"
              placeholder="请输入身份证号码"
              @input="change2"
              clearable
              onkeyup="this.value=this.value.replace(/[^\X0-9]/g, '')"
              maxlength="18"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="联系方式："
            prop="mobile"
          >
            <el-input
              v-model="merchantsForm.mobile"
              placeholder="请输入联系方式"
              oninput="value=value.replace(/[^\d]/g,'')"
              clearable
            ></el-input>
          </el-form-item>
        </div>
        <div class="third">
          <el-form-item
            label="店铺名称："
            prop="shopName"
          >
            <el-input
              v-model="merchantsForm.merchantsInfo.shopName"
              placeholder="请输入店铺名称"
              clearable
              @input="change"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="经营范围："
            prop="merchantCategoryId"
          >
            <el-select
              v-model="newArr"
              placeholder="请选择经营范围"
              clearable
              multiple
              class="select"
              @change="select"
            >
              <el-option
                v-for="(item, index) in cateList"
                :key="index"
                :label="item.categoryName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>

        <el-form-item
          label="摊位号："
          prop="stallId"
        >
          <el-select
            v-model="merchantsForm.merchantsInfo.stallId"
            placeholder="请选择摊位号"
            clearable
            multiple
            filterable
            class="select"
            @change="select"
          >
            <el-option
              v-for="(item, index) in stallList"
              :key="index"
              :label="item.stallName"
              :value="item.id"
              :disabled="item.disable"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="店铺有效期："
          prop="time"
        >
          <el-date-picker
            v-model="time"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            @change="chooseTime"
          >
          </el-date-picker>
        </el-form-item>
        <div class="five">
          <el-form-item label="店铺头像：">
            <el-upload
              action="/api/v1/file/file"
              :data="this.bizType1"
              :before-upload="beforeUpload"
              :on-success="handlesuccess1"
              :show-file-list="false"
            >
              <img
                v-if="merchantsForm.avatar"
                :src="merchantsForm.avatar"
                class="avatar"
              />
              <i
                v-else
                class="el-icon-picture-outline avatar-uploader-icon"
              ></i>
            </el-upload>
          </el-form-item>

          <el-form-item label="营业执照：">
            <el-upload
              action="/api/v1/file/file"
              :data="this.bizType2"
              :before-upload="beforeUpload"
              :on-success="handlesuccess2"
              :show-file-list="false"
            >
              <img
                v-if="businessLicence"
                :src="businessLicence"
                class="avatar"
              />
              <i
                v-else
                class="el-icon-picture-outline avatar-uploader-icon"
              ></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="健康证：">
            <el-upload
              action="/api/v1/file/file"
              :data="this.bizType3"
              :before-upload="beforeUpload"
              :on-success="handlesuccess3"
              :show-file-list="false"
            >
              <img
                v-if="merchantsForm.merchantsInfo.healthCertificate"
                :src="merchantsForm.merchantsInfo.healthCertificate"
                class="avatar"
              />
              <i
                v-else
                class="el-icon-picture-outline avatar-uploader-icon"
              ></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="安全许可证：">
            <el-upload
              action="/api/v1/file/file"
              :data="this.bizType4"
              :before-upload="beforeUpload"
              :on-success="handlesuccess4"
              :show-file-list="false"
            >
              <img
                v-if="merchantsForm.merchantsInfo.hygienicLicense"
                :src="merchantsForm.merchantsInfo.hygienicLicense"
                class="avatar"
              />
              <i
                v-else
                class="el-icon-picture-outline avatar-uploader-icon"
              ></i>
            </el-upload>
          </el-form-item>
        </div>
        <div class="six">
          <el-form-item label="微信收款二维码：">
            <el-upload
              action="/api/v1/file/file"
              :data="this.bizType5"
              :on-success="handlesuccess5"
              :show-file-list="false"
              :http-request="resolveQR1"
            >
              <img
                v-if="merchantsForm.merchantsInfo.wxQrCode"
                :src="merchantsForm.merchantsInfo.wxQrCode"
                class="avatar"
              />
              <i
                v-else
                class="el-icon-picture-outline avatar-uploader-icon"
              ></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="支付宝收款二维码：">
            <el-upload
              action="/api/v1/file/file"
              :data="this.bizType6"
              :on-success="handlesuccess6"
              :show-file-list="false"
              :http-request="resolveQR2"
            >
              <img
                v-if="merchantsForm.merchantsInfo.zfbQrCode"
                :src="merchantsForm.merchantsInfo.zfbQrCode"
                class="avatar"
              />
              <i
                v-else
                class="el-icon-picture-outline avatar-uploader-icon"
              ></i>
            </el-upload>
          </el-form-item>
        </div>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="warning"
          @click="goOn"
          v-if="isShowGoOn"
        >保存并继续</el-button>
        <el-button
          type="primary"
          @click="submit"
        >确 定</el-button>
        <el-button @click="addShopDialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getQrUrl } from '../../api/common.js'
import QrCode from 'qrcode-decoder'
export default {
  data () {
    // 手机号校验规则
    var checkMobile = (rule, value, callback) => {
      const regMobile = /^(0|86|17951)?(1[0-9])[0-9][0-9]{8}$/
      if (regMobile.test(value)) {
        return callback()
      }
      callback(new Error('请输入合法的手机号'))
    }
    // 身份证号校验
    // var checkIdNum = (rule, value, callback) => {
    //   const reg = /(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)/
    //   if (reg.test(value)) {
    //     return callback()
    //   }
    //   callback(new Error('请输入合法的身份证'))
    // }
    return {
      // 搜索入参
      queryInfo: {
        userName: '', // 商户名称
        shopName: '', // 店铺名称
        limit: 15,
        pageNo: 1,
        status: '', // 状态，0-启用 1-禁用 2-待完善
        categoryName: '', // 经营范围
        businessBegin: '',
        businessEnd: '',
        marketId: '',
        merchantCategoryId: ''
      },
      // 列表数据
      shopList: [],
      // 总数
      total: 0,
      // 添加对话框,默认关闭
      addShopDialogVisible: false,
      // 添加商家用户的入参
      merchantsForm: {
        avatar: '',
        email: '',
        marketId: undefined, // 市场id
        mobile: '',
        userName: '', // 商户名称
        sex: '', // 性别 0-女 1-男 2-未设置
        status: '', // 状态 0-启用 1-禁用 2-待完善
        merchantsInfo: {
          businessBegin: '',
          businessEnd: '',
          businessLicence: [], // 营业执照
          floorSendCost: '', // 起送金额，分
          healthCertificate: '', // 健康证
          hygienicLicense: '', // 卫生许可证
          idCardImg: [], // 身份证照片
          idCardNum: '', // 身份证号码
          merchantCategoryId: [], // 业态id
          notice: '', // 公告
          shopName: '',
          stallId: [], // 摊位id
          stallName: '', // 摊位名称
          wxQrCode: '', // 微信收款码
          zfbQrCode: '', // 支付宝收款码
          aliPayQrCode: '',
          wxPayQrCode: ''
        }
      },
      // 营业执照
      businessLicence: '',
      // 标题名称
      title: '',
      // 提交类型
      submitType: '',
      // 修改时需要的id
      id: '',
      // 上传个人头像的type
      bizType1: {
        bizType: 'shopUser'
      },
      bizType2: {
        bizType: 'businessLicence'
      },
      bizType3: {
        bizType: 'healthCertificate'
      },
      bizType4: {
        bizType: 'hygienicLicense'
      },
      bizType5: {
        bizType: 'wxQrCode'
      },
      bizType6: {
        bizType: 'zfbQrCode'
      },
      // 营业执照
      businessImageFileList: [],
      // 查询所有市场的入参
      marketQueryInfo: {
        limit: 100,
        pageNo: 1
      },
      marketList: [],
      // 验证规则
      addShopFormRules: {
        // 用户名称必填
        mobile: [
          { required: true, message: '请输入联系方式', trigger: 'blur' },
          { validator: checkMobile, trigger: 'blur' }
        ],
        userName: [
          { required: true, message: '请输入用户名', trigger: 'blur' }
        ],
        sex: [{ required: true, message: '请选择性别', trigger: 'blur' }],
        id: [
          { required: false, message: '请输入身份证号码', trigger: 'change' }
        ]

        // idCardNum: [{ validator: checkIdNum, trigger: 'blur' }]
      },
      // 商品类别
      cateList: [],
      // 日期
      data: '',
      // 状态映射
      statusList: [
        {
          id: 0,
          name: '启用'
        },
        {
          id: 1,
          name: '禁用'
        },
        {
          id: 3,
          name: '待完善'
        }
      ],
      // 性别
      sexList: [
        {
          id: 1,
          name: '男'
        },
        {
          id: 0,
          name: '女'
        }
      ],
      // 店铺有效期
      time: undefined,
      // 营业执照的url
      businessUrl: '',
      // 获取摊位号的入参
      stallQueryInfo: {
        limit: 1000,
        pageNo: 1,
        marketId: ''
        // status: 0
      },
      // 摊位数组
      stallList: [],
      // 商户具体信息
      merchantInfo: {},
      // 经营范围新数组
      newArr: [],
      // 是否需要展示保存并继续按钮
      isShowGoOn: true
    }
  },
  created () {
    // 调用列表接口
    this.searchShop()
    // 调用商品类别接口
    this.getGoodCateList()
    // 获取摊位号
    this.getStallList()
  },
  methods: {
    searchShopList () {
      this.queryInfo.pageNo = 1
      this.searchShop()
    },
    // 搜索请求
    async searchShop () {
      // 获取marketId
      this.queryInfo.marketId = window.sessionStorage.getItem('currentMarketId')
      const { data: res } = await this.$http.get('merchants/list', {
        params: this.queryInfo
      })
      if (res.code !== 0) {
        return this.$message.error(res.message)
      }
      this.shopList = res.data.data
      this.total = res.data.total
    },
    // 调用业态
    async getGoodCateList () {
      const { data: res } = await this.$http.get(
        'merchantCategory/list?limit=200&pageNo=1'
      )
      if (res.code !== 0) {
        return this.$message.error('获取业态失败')
      }
      this.cateList = res.data.data
    },
    // limit变化，调用列表接口
    handleSizeChange (newSize) {
      this.queryInfo.limit = newSize
      this.searchShop()
    },
    // 页码变化，调用接口
    handleCurrentChange (newPage) {
      this.queryInfo.pageNo = newPage
      this.searchShop()
    },
    // 打开添加商户对话框
    showAddShopDialog () {
      // 查询所有市场的信息
      this.getMarketList()
      this.title = '新增商户'
      this.submitType = 'add'
      this.isShowGoOn = true
      // 赋值marketId
      this.merchantsForm.marketId = window.sessionStorage.getItem(
        'currentMarketId'
      )
      this.addShopDialogVisible = true
    },
    // 提交添加信息
    async submit () {
      // 先把经营范围给赋值
      this.merchantsForm.merchantsInfo.merchantCategoryId = this.newArr
      if (this.submitType === 'add') {
        // 校验规则，必填
        this.$refs.addShopFormRef.validate(async valid => {
          if (!valid) return
          // 可以发起网络请求
          const { data: res } = await this.$http.post(
            'merchants',
            this.merchantsForm
          )
          if (res.code !== 0) {
            return this.$message.error(res.message)
          }
          this.$message.success('添加商家用户成功')
          this.addShopDialogVisible = false
        })
      } else {
        // 校验规则，必填
        this.$refs.addShopFormRef.validate(async valid => {
          if (!valid) return
          // 赋值成功后提交
          const { data: res } = await this.$http.put(
            `merchants/${this.id}`,
            this.merchantsForm
          )
          if (res.code !== 0) {
            return this.$message.error(res.message)
          }
          this.$message.success('修改商家用户信息成功')
          this.addShopDialogVisible = false
        })
      }
    },
    // 删除商户
    async removeShopById (id) {
      // 弹框提醒
      const confirmResult = await this.$confirm(
        '此操作将永久删除该商户，是否继续？',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消删除')
      }
      const { data: res } = await this.$http.delete(`merchants/${id}`)
      if (res.code !== 0) {
        return this.$message.error(res.message)
      }
      this.$message.success('删除商家用户成功')
      this.searchShop()
    },
    // 修改对话框
    showEditDialog (id) {
      this.title = '编辑商家用户'
      this.submitType = 'edit'
      this.isShowGoOn = false
      // 赋值
      this.id = id
      // 调用详情接口
      this.getMerchantInfo()
      this.addShopDialogVisible = true
    },
    // 调用详情接口
    async getMerchantInfo () {
      const { data: res } = await this.$http.get(`merchants/${this.id}`)
      if (res.code !== 0) {
        return this.$message.error(res.message)
      }
      this.merchantsForm = res.data
      // this.merchantsForm.merchantsInfo.stallId = [22, 23]
      // 时间格式转换
      if (this.merchantsForm.merchantsInfo?.businessEnd) {
        this.time = [
          this.merchantsForm.merchantsInfo.businessBegin,
          this.merchantsForm.merchantsInfo.businessEnd
        ]
      }
      // 将经营范围赋值给一个新的数组
      this.newArr = this.merchantsForm.merchantsInfo.merchantCategoryList.map(
        function (item, index) {
          return item.merchantCategoryId
        }
      )
      console.log(this.newArr)
      // 将营业执照链接给
      // this.businessLicence = this.merchantsForm.merchantsInfo.businessLicence[0]
      if (this.merchantsForm.merchantsInfo?.businessLicence?.length > 0) {
        this.businessLicence = this.merchantsForm.merchantsInfo.businessLicence[0]
      }
    },
    beforeUpload (file) {
      const types = ['image/jpeg', 'image/jpg', 'image/png', 'image/bmp']
      const isImage = types.includes(file.type)
      if (!isImage) {
        this.$message.error('上传图片只能是jpg、jpeg、bmp、png格式')
        return false
      }
      return true
    },
    // 监听头像上传成功的钩子
    handlesuccess1 (response, file, fileList) {
      this.$forceUpdate()
      this.merchantsForm.avatar = response.data
    },
    // 监听上传成功的钩子
    handlesuccess2 (response, file, fileList) {
      this.$forceUpdate()
      this.businessLicence = response.data
      this.merchantsForm.merchantsInfo.businessLicence = [response.data]
    },
    handlesuccess3 (response, file, fileList) {
      this.$forceUpdate()
      this.merchantsForm.merchantsInfo.healthCertificate = response.data
      console.log(this.merchantsForm.merchantsInfo.healthCertificate)
    },
    handlesuccess4 (response, file, fileList) {
      this.$forceUpdate()
      this.merchantsForm.merchantsInfo.hygienicLicense = response.data
    },
    handlesuccess5 (response, file, fileList) {
      this.$forceUpdate()
      this.merchantsForm.merchantsInfo.wxQrCode = response.data
    },
    handlesuccess6 (response, file, fileList) {
      this.$forceUpdate()
      this.merchantsForm.merchantsInfo.zfbQrCode = response.data
    },
    // 调用查询所有市场
    async getMarketList () {
      const { data: res } = await this.$http.get(
        'agriculturalMarket/marketList',
        {
          params: this.marketQueryInfo
        }
      )
      if (res.code !== 0) {
        return this.$message.error('查询市场失败')
      }
      this.marketList = res.data.data
    },
    // 关闭对话框
    clearInfo () {
      this.merchantsForm.avatar = ''
      this.merchantsForm = {}
      this.merchantsForm.merchantsInfo = {}
      this.time = []
      this.merchantsForm.merchantsInfo.businessLicence = []
      this.businessLicence = ''
      // 保证在当前市场下
      this.merchantsForm.marketId = window.sessionStorage.getItem(
        'currentMarketId'
      )
      // 把经营范围数组清空
      this.newArr = []
      // 调用列表接口
      this.searchShop()
    },
    // 跳转到商户详情
    goShopDetail (id) {
      this.$router.push({ path: '/merchants', query: { id: id } })
    },
    // 跳转到供应商详情
    goSupplier (id) {
      this.$router.push({ path: '/supplier', query: { id: id } })
    },
    // 跳转到食品安全检测数据详情
    goQualityTest (id) {
      this.$router.push({ path: '/qualityTest', query: { id: id } })
    },
    // 调整到评价列表
    goComment (id) {
      this.$router.push({ path: '/review', query: { id: id } })
    },
    // 选择日期
    chooseTime () {
      console.log(this.time)
      // 赋值给入参
      this.merchantsForm.merchantsInfo.businessBegin = this.time[0]
      this.merchantsForm.merchantsInfo.businessEnd = this.time[1]
    },
    // 强制刷新input
    change (e) {
      this.$forceUpdate()
    },
    // 保存并继续
    async goOn () {
      const { data: res } = await this.$http.post(
        'merchants',
        this.merchantsForm
      )
      if (res.code !== 0) {
        return this.$message.error(res.message)
      }
      this.$message.success('添加商家用户成功')
      this.searchShop()
      this.merchantsForm = {}
      this.merchantsForm.merchantsInfo = {}
      this.newArr = []
      this.time = []
      this.merchantsForm.merchantsInfo.businessLicence = []
      this.businessLicence = ''
    },
    // 禁用
    async forbidden (row) {
      const { data: res } = await this.$http.put(
        `merchants/updateStatus?id=${row.id}&status=1`
      )
      if (res.code !== 0) {
        return this.$message.error(res.message)
      }
      this.$message.success('禁用成功')
      this.searchShop()
    },
    // 启用
    async start (row) {
      const { data: res } = await this.$http.put(
        `merchants/updateStatus?id=${row.id}&status=0`
      )
      if (res.code !== 0) {
        return this.$message.error(res.message)
      }
      this.$message.success('启用成功')
      this.searchShop()
    },
    // 获取摊位号
    async getStallList () {
      // 获取marketId
      this.stallQueryInfo.marketId = window.sessionStorage.getItem(
        'currentMarketId'
      )
      const { data: res } = await this.$http.get(
        'agriculturalMarketStall/list',
        {
          params: this.stallQueryInfo
        }
      )
      if (res.code !== 0) {
        return this.$message.error('获取摊位号失败')
      }
      // 赋值
      this.stallList = res.data.data
    },
    // 强制更新
    select () {
      this.$forceUpdate()
    },
    // 识别微信
    resolveQR (event) {
      console.log(event)
      const result = getQrUrl(event.file)
      result
        .then(res => {
          if (res.data) {
            console.log(res.data)
            this.merchantsForm.merchantsInfo.wxPayQrCode = res.data
            this.$message.success('识别二维码成功!')
          } else {
            this.$message.error('识别二维码失败, 请重新上传')
          }
        })
        .catch(err => {
          this.$message.error(JSON.stringify(err))
        })
    },
    resolveQR1 (event) {
      console.log(event)
      const result = getQrUrl(event.file)
      result
        .then(res => {
          if (res.data) {
            console.log(res.data)
            this.merchantsForm.merchantsInfo.wxPayQrCode = res.data
            this.$message.success('微信二维码识别成功!')
          } else {
            this.$message.error('识别二维码失败, 请重新上传')
          }
        })
        .catch(err => {
          this.$message.error(JSON.stringify(err))
        })
      var form = new FormData()
      form.append('file', event.file)
      form.append('bizType', 'wxQrCode')
      this.$http.post('/file/file', form).then(res2 => {
        console.log(res2)
        this.$forceUpdate()
        this.merchantsForm.merchantsInfo.wxQrCode = res2.data.data
        // 上传成功 调用onSuccess方法，
        // 处理自己的逻辑
        // this.handlesuccess5(res2) // 必须传参res,否则在onsuccess的参数里面，response属性值为undefined，如果传了值，则会把res的值作为response的属性值
      })
    },
    // 识别支付宝
    resolveQR2 (event) {
      const result = getQrUrl(event.file)
      result
        .then(res => {
          if (res.data) {
            console.log(res.data)
            this.merchantsForm.merchantsInfo.aliPayQrCode = res.data
            this.$message.success('支付宝二维码识别成功!')
          } else {
            this.$message.error('识别二维码失败, 请重新上传')
          }
        })
        .catch(err => {
          this.$message.error(JSON.stringify(err))
        })
      var form = new FormData()
      form.append('file', event.file)
      form.append('bizType', 'zfbQrCode')
      this.$http.post('/file/file', form).then(res2 => {
        console.log(res2)
        this.$forceUpdate()
        this.merchantsForm.merchantsInfo.zfbQrCode = res2.data.data
        // 上传成功 调用onSuccess方法，
        // 处理自己的逻辑
        // this.handlesuccess5(res2) // 必须传参res,否则在onsuccess的参数里面，response属性值为undefined，如果传了值，则会把res的值作为response的属性值
      })
    },
    async handleClick () {
      var qr = new QrCode()
      const res = await qr.decodeFromImage(
        this.merchantsForm.merchantsInfo.wxQrCode,
        {
          crossOrigin: '*'
        },
        {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
      if (res) {
        this.merchantsForm.merchantsInfo.wxPayQrCode = res.data
        console.log(this.merchantsForm.merchantsInfo.wxPayQrCode)
      }
    },
    // 身份证校验
    // 身份证验证
    async validID (rule, value, callback) {
      // 身份证号码为15位或者18位，15位时全为数字，18位前17位为数字，最后一位是校验位，可能为数字或字符X
      const reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
      if (reg.test(value)) {
        await this.go(value.length)
        callback()
      } else {
        callback(new Error('身份证号码不正确'))
      }
    },
    // 取消身份证校验
    change2 () {
      this.$forceUpdate()
      // this.$refs.file_Rule.clearValidate()
      // console.log(this.merchantsForm.merchantsInfo.idCardNum)
    }
  }
}
</script>

<style lang="less" scoped>
.el-row {
  margin-bottom: 20px;
  width: 1366px;
}
.el-col {
  margin-right: 20px;
}
// .el-col:last-child {
//   margin-left: 110px;
// }
// img {
//   width: 70px;
//   height: 70px;
// }
.businessImg {
  img {
    display: block;
    margin-top: 10px;
    // width: 200px;
    // height: 200px;
    object-fit: cover;
  }
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
}
.avatar {
  width: 80px;
  height: 80px;
  display: block;
}
.first,
.second,
.third {
  display: flex;
  justify-content: space-between;
}

.six,
.five {
  display: flex;
  justify-content: flex-start;
}
.input {
  display: flex;
  width: 340px;
  align-items: center;
  justify-content: center;
  margin-right: -20px;
}
.input:nth-child(3) {
  margin-left: -30px;
}
.input:last-child {
  margin-left: -60px;
}
.el-select {
  // width: 215px;
  width: 150px;
}
.select {
  width: 215px;
}
.el-input {
  width: 215px;
}
.secondrow {
  display: flex;
  width: 1227px;
  margin-left: -15px;
}
</style>
